import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, CardContent, Rating, Grid, Typography, CardActionArea } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Exam from '../components/Exam.js'
import examData from '../data/exam.json'


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

function Home({allowedExams}) {
  return (
    <main>
            <Container sx={{ py: 15 }}>
              <Grid container spacing={5}>
                {examData &&
                  examData.map((item) => {
                      let hasAccess = false
                      if(allowedExams){
                        hasAccess = allowedExams.split(",").includes(String(item.id))
                      }
                    return <Exam key={item.id} exam={item} hasAccess={hasAccess}/>
                  }
                )}
              </Grid>
            </Container>
          </main>
  );
}

export default Home;
