import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postRequest } from '../util/APIUtils';
import DoneIcon from '@mui/icons-material/Done';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Box, Button, Container, Grid, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

const TestDetail = () => {  
  const location = useLocation()
  const { attemptedTestId,quizTitle, date, totalScore } = location.state // Read values passed on state
  const [testData, setTestData] = useState([]);

  useEffect(() => {
    postRequest(`/api/atq/test-detail`, {attemptedTestId: attemptedTestId})
    .then(result => {
        setTestData(result);
        })
        .catch(error => {
        console.error('Error fetching data:', error);
        });
  }, []);

  
  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <Card key={100} elevation={5} style={{backgroundColor: "#FF4d00"}}>
          <CardContent>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left" color="white"><b>{quizTitle}</b></Typography>
              <Typography variant="body1" align="right" color="white">Score:{totalScore}</Typography>
              <Typography variant="body1" align="right" color="white">{date}</Typography>
            </Stack>  
          </CardContent>
        </Card>
        </Grid>
      {testData && testData.map((test, index) => (
        <Grid item xs={12}>
        <Card key={index} elevation={5} style={test.score>0?{backgroundColor: "#e8f4ea"}:test.answer?{backgroundColor: "#ffcccc"}:''}>
          <CardContent>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left" color="text.primary">{'Q'+(index + 1)+'. '+test.question}</Typography>
              <Typography variant="body1" align="right" color="text.secondary"><b>Score:</b>{test.score}</Typography>
            </Stack>  
            {test.option1? 
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="right" color="text.secondary">{1+'.'+test.option1}{test.correctAnswer == 1?<DoneIcon/>:test.answer == 1?<CancelTwoToneIcon/>:''}</Typography>
              <Typography variant="body1" align="right" color="text.secondary">{2+'.'+test.option2}{test.correctAnswer == 2?<DoneIcon/>:test.answer == 2?<CancelTwoToneIcon/>:''}</Typography>
              <Typography variant="body1" align="right" color="text.secondary">{3+'.'+test.option3}{test.correctAnswer == 3?<DoneIcon/>:test.answer == 3?<CancelTwoToneIcon/>:''}</Typography>
              <Typography variant="body1" align="right" color="text.secondary">{4+'.'+test.option4}{test.correctAnswer == 4?<DoneIcon/>:test.answer == 4?<CancelTwoToneIcon/>:''}</Typography>
              {test.option5?<Typography variant="body1" align="right" color="text.secondary">{5+'.'+test.option5}{test.correctAnswer == 5?<DoneIcon/>:test.answer == 5?<CancelTwoToneIcon/>:''}</Typography>:''}
            </Stack>
            :
            <Stack direction="column" sx={{justifyContent:"space-between"}}>
                <Typography variant="body1" align="left" color="text.secondary"><b>Your Answer:</b>{test.answer}</Typography>
                <Typography variant="body1" align="left" color="text.secondary"><b>Correct Answer:</b>{test.correctAnswer}</Typography>
                {test.mistakes?
                <Typography variant="body1" align="left" color="text.secondary"><b>Mistakes:</b>{test.mistakes}</Typography>:''
                }
            </Stack>
            }
                  
                    
          </CardContent>
        </Card>
        </Grid>
      ))}
    </Grid>
    </Container>
  );
};

export default TestDetail;
