import React,{ useState } from 'react'
import './Quiz.css'
import { styled } from '@mui/material/styles';
import { Stack, Chip, Card, CardMedia, CardContent, Rating, Grid, Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions, Button, Slide } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Typography, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import { yellow } from '@mui/material/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function Exam({ exam, hasAccess }) {

  const navigate = useNavigate()
  const [showComingSoon, setShowComingSoon] = useState(false)

  const buyNow = () => {
    navigate('/checkout', {state:{exam: exam}})
  };

  const gotoExam = () => {
    if(exam.id>100){
      setShowComingSoon(true)
      setTimeout(function () {
          setShowComingSoon(false)
      }, 1500);//1.5 Second delay   
      return
    }        
    navigate('/quiz', { state: { exam_id: exam.id, pattern: exam.pattern, syllabus: exam.syllabus } });
  }


  return (
    <Grid item key={exam.id} xs={12} md={3} style={{ display: 'flex' }}>
      <Card sx={{ minWidth: 250}} elevation={4}>
        <CardActionArea onClick={hasAccess?gotoExam:exam.price>0?buyNow:void(0)}>
          <CardMedia
            component="img"
            image={exam.url}
            alt={exam.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {exam.title}
            </Typography>
            <StyledRating
              name="customized-color"
              size="small"
              defaultValue={exam.rating}
              getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={0.5}
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
           <Stack direction="row" justifyContent="space-between">  
           {exam.price>0?    
              <Typography variant="h5" component="div">&#x20b9;{exam.price}</Typography>:
              <Typography variant="button" component="div">{exam.price}</Typography>}
              <Typography variant="button" component="div">MRP: <del>&#x20b9;{exam.mrp}</del>
            </Typography>
            </Stack>
            <Typography variant="body2" component="div">Valid Until : {exam.valid}</Typography>

          </CardContent></CardActionArea>
          <CardContent>            
            {hasAccess? 
            <Chip icon={<NotStartedOutlinedIcon />} label="Start Exam" sx={{backgroundColor:yellow[500], fontWeight:'bold'}} variant="outlined" onClick={gotoExam}/>
            : exam.price> 0?
            <Chip icon={<ShoppingCartOutlinedIcon />} label="Buy Now" sx={{backgroundColor:yellow[500], fontWeight:'bold'}} variant="outlined" onClick={buyNow}/>:
            ''
            }
          </CardContent>
      </Card>
      <Dialog open={showComingSoon}
        fullWidth TransitionComponent={Transition}
        keepMounted aria-describedby="alert-dialog-slide-description">
        <DialogTitle textAlign="center">Coming Soon</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" textAlign="center">
          Keep watching for this space.
          </DialogContentText>
        </DialogContent>    
      </Dialog>
    </Grid>
    
  )
}
