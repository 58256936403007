import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, FormGroup, FormControlLabel, RadioGroup, Radio, Checkbox, Card, Paper, Grid, Container,CardMedia, Button, CardContent, Typography, Stack, TextField, Link, CardHeader } from '@mui/material';
import {Breadcrumbs, Dialog, DialogActions,DialogContent,DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup, Pagination, Slide} from '@mui/material';
import {styled } from '@mui/material/styles';
import { getRequest, postRequest } from '../util/APIUtils';
import DOMPurify from 'dompurify';
import { purple } from '@mui/material/colors';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  textAlign: 'center',
  textTransform: 'none',
  borderRadius: 5,
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

function QuestionPage() {
  const location = useLocation()
  // quiz params coming from parent page
  const { quiz_id, attempted_test_id, deadline, correct_marks, incorrect_marks, comprehension_count, total_questions, exam_mode, exam_name, quiz_title } = location.state
  const [questionIndex, setQuestionIndex] = useState(0)
  const [questionDisplayIndex, setQuestionDisplayIndex] = useState(0)
  const [resultIndex, setResultIndex] = useState(0)
  const [resultCount, setResultCount] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [questionsInComprehension, setQuestionsInComprehension] = useState(0)
  const [correctQuestions, setCorrectQuestions] = useState(0)
  const [score, setScore] = useState(0)
  const [questions, setQuestions] = useState(null)
  const [comprehensions, setComprehensions] = useState(null)
  const [showAnswerDialog, setShowAnswerDialog] = useState(false)
  const [showReportQuestion, setShowReportQuestion] = useState(false)
  const [showFinalSubmitDialog, setShowFinalSubmitDialog] = useState(false)
  const [showRefreshDialog, setShowRefreshDialog] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const [selectedResponse, setSelectedResponse] = useState("")
  const [responseDict, setResponseDict] = useState({})

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [isChecked, setIsChecked] = useState([false, false, false, false, false, false])
  const [page, setPage] = useState(1)

  const nextRef = useRef(null);


  let navigate = useNavigate();
  //  const styleButton = {backgroundColor: '#FFC100', borderColor: '#ff0000', color: '#000000', borderRadius: '1px', fontSize: '2rem'}

  const showComprehensionImages = () => {

    if(comprehensions && comprehensions[resultIndex].id > 0 && comprehensions[resultIndex].imageUrl){
      return comprehensions[resultIndex].imageUrl.split(",").map(itemURL => 
        <CardMedia
            title="Comprehension Picture">
              <img src={itemURL} style={{ height: 200, width: 350}}/></CardMedia>)
    }
  }
  const buildBottomPanel = () => {    
    let prevButton = questionDisplayIndex==0?"":<Button ref={nextRef} variant="contained" size="small" autoFocus onClick={handlePrev}>Prev</Button>
    let nextButton = questionDisplayIndex==totalQuestions-1?"":<Button ref={nextRef} variant="contained" autoFocus onClick={handleNext}>Next</Button>
    let numberPanel = [...Array(totalQuestions)].map((optionItem, index) =><Typography gutterBottom variant="body" 
    component="div" marginRight={1} marginLeft={1}>{index == questionDisplayIndex ? index+1:<Link component="button"
    variant="body2" onClick={handleNumberPanel} value={index+1}>{index+1}</Link>}</Typography>)  
    let bottomPanel = [prevButton, numberPanel, nextButton]  
    //return <Stack direction="row" sx={{justifyContent:"space-between"}}>{bottomPanel}</Stack>
    return <Pagination variant="outlined" shape="rounded" count={totalQuestions} defaultPage={1} siblingCount={3} boundaryCount={3} showFirstButton showLastButton onChange={handleNumberPanel}/>

  }
  const buildResponseTile = () => {
      if(comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "select") {
          let optionsCount = 4
          if(comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option5`] && comprehensions[resultIndex].questions[questionIndex][`option5`].length>0){
            optionsCount = 5
          }
          return <RadioGroup name="options" row onChange={handleChoiceResponse} value={selectedResponse}>{[...Array(optionsCount)].map((optionItem, index) =>
            <Grid key={index} item xs={6} alignItems={"left"}><FormControlLabel align="left" sx={{width:"100%"}} key={index} value={index + 1} control={<Radio/>} 
            label={comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]} 
            /></Grid>
          )}</RadioGroup>
          return <>{[...Array(optionsCount)].map((optionItem, index) =>
          <Grid key={index} item xs={6}><Item key={index} elevation={3} onClick={handleChoiceResponse}>
          {comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]} 
                </Item></Grid>)}
          </>
          // return <ToggleButtonGroup
          // color="primary"
          // value={selectedResponse}          
          // exclusive
          // onChange={handleChoiceResponse}
          // aria-label="options">
          //   {[...Array(optionsCount)].map((optionItem, index) =>
          //   <ToggleButton value={index + 1} aria-label={comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]}>
          //     {comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]} 
          //   </ToggleButton>
             
          //   )}</ToggleButtonGroup>
      } 
      else if(comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "multi") {
        let optionsCount = 4
        if(comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option5`] && comprehensions[resultIndex].questions[questionIndex][`option5`].length>0){
          optionsCount = 5
        }
        return <FormGroup>{[...Array(optionsCount)].map((optionItem, index) =>
          <FormControlLabel align="left" sx={{width:"100%"}} key={index} value={index + 1} control={<Checkbox size="small" key={index} checked={isChecked[index]}
           onChange={() => handleMultiResponse(index)}/>} 
          label={comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]} 
          />
        )}</FormGroup>
      } 
      else if (comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "input") {
        return <Grid item xs={12}><TextField
        id="input-1"
        label="Respond here"
        variant="outlined"
        size="small"
        onChange = {handleChange}
        style={{ marginRight: "10px" }}
        value={selectedResponse}
      />
      <Button variant="contained" sx={{ textTransform: "none"}} onClick={handleInputResponse}>Lock Answer</Button>
      </Grid>
      }
      else if (comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "essay") {
        let areaComponent = <TextField
        id="outlined-multiline-static"
        label="Answer"
        helperText='Please save it frequently using "Save Answer" button below to avoid losing your work.'
        multiline
        rows={10}
        onChange = {handleChange}
        value={selectedResponse}
        fullWidth
      />
      let buttonComponent = 
      <Button variant="contained" sx={{ textTransform: "none", marginTop: 2}} onClick={() => {handleInputResponse(false, false)}}>Save Answer</Button>

      return [areaComponent, buttonComponent]
    }
      else if (comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "sequence") {
          let optionsComponent = [...Array(4)].map((optionItem, index) =>
            <Grid item xs={6} key={index}>
            <Button variant="contained" sx={{ textTransform: "none"}} fullWidth onClick={handleChoiceResponse} value={index+1}>                
              {comprehensions && comprehensions[resultIndex].questions && comprehensions[resultIndex].questions[questionIndex][`option` + (index + 1)]}
            </Button>
          </Grid>
          )
          let responseComponent = 
          <Grid xs={12} style={{ marginTop: "10px" }}><TextField
        id="input-1"
        variant="outlined"
        size="small"
        inputProps={{ maxLength: 4 }}
        onChange = {handleChange}
        style={{ marginRight: "10px",width: "5em" }}
        value={selectedResponse}
        label="_ _ _ _"
      /> 
      <Button variant="contained" sx={{ textTransform: "none"}} onClick={handleInputResponse}>Lock Sequence</Button>
      </Grid>
      return [optionsComponent, responseComponent]
      }
  }
  const getTime = () => {
      const time = deadline - Date.now()
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
      let minutesInTimer = String(Math.floor((time / 1000 / 60) % 60))
      let secondsInTimer = String(Math.floor((time / 1000) % 60))
      if(secondsInTimer.length === 1){
        secondsInTimer = '0'+secondsInTimer
      }
      if(minutesInTimer.length === 1){
        minutesInTimer = '0'+minutesInTimer
      }
      setMinutes(minutesInTimer)
      setSeconds(secondsInTimer)
      if(time <=0 ){
        calculateScoreAndRedirectToResultPage()
      }
  }

  const playClip = () => {
    const audioControl = document.querySelector(`#${'audio_tag'}`);
    
    if(audioControl) {
        audioControl.load();
        audioControl.play();
    }
  }
 
  useEffect(() => {
    //TODO: Test if we can move this to load only once at the beginning    
    if(typeof window?.MathJax !== "undefined"){
      window.MathJax.typeset()
    }  
    const interval = setInterval(() => getTime(), 1000)
    return () => clearInterval(interval)
  }, [getTime])

  useEffect(() => {
    //console.log('adding event listener')
    //window.addEventListener('beforeunload', handleBeforeUnload)
    getRequest(`/api/question/${quiz_id}`)
    .then(result => {
      setComprehensions(result)
      setResultCount(result.length)
      setQuestions(result[resultIndex].questions)
      const count = result.reduce((sum, item) => 
        {return sum += item.questions.length},0)        
      setTotalQuestions(count)
      setQuestionsInComprehension(result[resultIndex].questions.length)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    // return () => {
    //   console.log('removing event listener')

    //   window.removeEventListener('beforeunload', handleBeforeUnload)
    // }
   
  }, [quiz_id]);
  
 
  const handleBeforeUnload = (event) => {
    event.preventDefault()
    setShowRefreshDialog(true)
    return false
    
    // Custom logic to handle the refresh
    // Display a confirmation message or perform necessary actions
  }
  const handleAnswer = (value, score=true) => {
    let key = questions[questionIndex].id
    let set_number = questions[questionIndex].questionXrefs[0].setNumber
    //setResponseDict({...responseDict, key : value})
    let valueObj = {}
    
    setSelectedResponse(value)

    let current_score = 0
    if (value == questions[questionIndex].answer) {
      setCorrectAnswer(true)
      //scoring like this(and counting correct questions) is buggy because if a question is re-attempted and 
      //earlier it was incorrect but now its correct, so we should also undo previous incorrect marks.
      // setScore(score+correct_marks)
      // setCorrectQuestions(correctQuestions + 1)
      current_score = correct_marks
    }else{
      setCorrectAnswer(false)
      //setScore(score-incorrect_marks)
      current_score = -incorrect_marks
    }
    if(score){
      valueObj = {"response":value, "correctAnswer":questions[questionIndex].answer, "score":current_score}
      postRequest('/api/atq/response',{"quizId":quiz_id, "attemptedTestId": attempted_test_id, "questionId":questions[questionIndex].id, "answer":value, "correctAnswer":questions[questionIndex].answer, "score": current_score, "setNumber":set_number})
    }else{
      valueObj = {"response":value}
      postRequest('/api/atq/response',{"quizId":quiz_id, "attemptedTestId": attempted_test_id, "questionId":questions[questionIndex].id, "answer":value, "setNumber":set_number})

    }
    setResponseDict(responseDict => {
      return {...responseDict, [key]:valueObj}
      })
    // handle skipped question
    //bring focus to next button

    //focus is not working yet.
    //nextRef.current.focus()
    //capture attempt     
    if(exam_mode === 'practice'){
      setShowAnswerDialog(true)
    }
  }

  const handleChoiceResponse =(e) => {
    return handleAnswer(e.target.value)
  }

  const handleMultiResponse =(index) => {
    //console.log("event target", e.target.value)
    //console.log(document.getElementById(""))
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
//    isChecked.map((v, i) => (v === true ? value=value+i:value))
  //  console.log("value", value)
    //return handleAnswer(index)
  }
  

  const handleChange = (e) => {
    // setInputValue(e.target.value)
    //to remove below or above
    setSelectedResponse(e.target.value)
  }
  const handleInputResponse = (score=true, moveNext=true) => {
    handleAnswer(selectedResponse, score)    
    //move to next question.
    if(questionDisplayIndex<totalQuestions-1){
      //setInputValue("")
      if(moveNext)
      {
        setSelectedResponse("") 
        handleNext()
      }      
    }
  }

  const submitExam = (evaluate) => {
    setShowFinalSubmitDialog(false)    
    let set_number = questions[0].questionXrefs[0].setNumber
    postRequest('/api/atq/response-all',{"quizId":quiz_id, "evaluateExam":evaluate, "attemptedTestId": attempted_test_id, "setNumber":set_number, "responseDict": responseDict})
    calculateScoreAndRedirectToResultPage()
  }
  const calculateScoreAndRedirectToResultPage = () => {
    let tempCorrectQuestions=0
    let tempScore = 0
    for (const [key, value] of Object.entries(responseDict)) {
      if(value.response == value.correctAnswer){
        tempCorrectQuestions = tempCorrectQuestions + 1
      }
      tempScore = tempScore + value.score
    }
    navigate('/result', { state: { correctQuestions: tempCorrectQuestions, totalQuestions: totalQuestions, score: tempScore, quizId:quiz_id, attemptedTestId: attempted_test_id, quizTitle: exam_name+" : "+quiz_title } })
  } 
  const handlePrev = () => {

    setShowAnswerDialog(false)
    setCorrectAnswer(false)
    setQuestionDisplayIndex(questionDisplayIndex - 1)
    if(questionIndex - 1 >= 0){
      setQuestionIndex(questionIndex - 1)
      if(responseDict[questions[questionIndex-1].id] !== undefined && responseDict[questions[questionIndex-1].id].response !== undefined){
        setSelectedResponse(responseDict[questions[questionIndex-1].id].response)    
      }else{
        setSelectedResponse("")
      }
    }
    else if(questionIndex - 1 < 0){
      if(resultIndex - 1 >= 0){
        setResultIndex(resultIndex-1)
        let tempQuestionIndex = comprehensions[resultIndex-1].questions.length - 1 
        setQuestionIndex(tempQuestionIndex)
        if(responseDict[comprehensions[resultIndex-1].questions[tempQuestionIndex].id] !== undefined && responseDict[comprehensions[resultIndex-1].questions[tempQuestionIndex].id].response !== undefined){
          setSelectedResponse(responseDict[comprehensions[resultIndex-1].questions[tempQuestionIndex].id].response)
        }else{
          setSelectedResponse("")
        }
        setQuestionsInComprehension(comprehensions[resultIndex-1].questions.length)
        setQuestions(comprehensions[resultIndex-1].questions)
      }
      // else if (resultIndex + 1 === resultCount)
      //  navigate('/result', { state: { correctQuestions: correctQuestions, totalQuestions: totalQuestions, score: score } })
    }
  }

  const handleNext = () => {
    setShowAnswerDialog(false)
    setCorrectAnswer(false)
    if(questionIndex + 1 < questionsInComprehension){
      setQuestionIndex(questionIndex + 1)
      if(responseDict[questions[questionIndex+1].id] !== undefined && responseDict[questions[questionIndex+1].id].response !== undefined){
        setSelectedResponse(responseDict[questions[questionIndex+1].id].response)    
      }else{
        setSelectedResponse("")
      }
      setQuestionDisplayIndex(questionDisplayIndex + 1)
    }
    else if(questionIndex + 1 === questionsInComprehension){
      if(resultIndex + 1 < resultCount){
        setResultIndex(resultIndex+1)
        setQuestionIndex(0)
        if(responseDict[comprehensions[resultIndex+1].questions[0].id] !== undefined && responseDict[comprehensions[resultIndex+1].questions[0].id].response !== undefined){
          setSelectedResponse(responseDict[comprehensions[resultIndex+1].questions[0].id].response)    
        }else{
          setSelectedResponse("")
        }
        setQuestionDisplayIndex(questionDisplayIndex + 1)
        setQuestionsInComprehension(comprehensions[resultIndex+1].questions.length)
        setQuestions(comprehensions[resultIndex+1].questions)
      }
      // Below should be moved to Submit button
      // also remove correctQuestions, totalQuestions variable - get data from the same API which gives my tests data.
      else if (resultIndex + 1 === resultCount){
        setQuestionDisplayIndex(questionDisplayIndex + 1)
        setQuestionIndex(questionIndex + 1)
        // navigate('/result', { state: { correctQuestions: correctQuestions, totalQuestions: totalQuestions, score: score } })
      }
    }
  }

  const handleNumberPanel = (event, value) => {
    //let newQuestionIndex = parseInt(event.target.value) - 1
    let newQuestionIndex = value - 1
    setPage(value)
    setShowAnswerDialog(false)
    setCorrectAnswer(false)
    setQuestionDisplayIndex(newQuestionIndex)
    let tempQuestionCount = 0    
    for(let i=0; i < resultCount; i++){
      tempQuestionCount = comprehensions[i].questions.length
      if(newQuestionIndex < tempQuestionCount){
        setResultIndex(i)
        setQuestionIndex(newQuestionIndex)
        if(responseDict[comprehensions[i].questions[newQuestionIndex].id] !== undefined && responseDict[comprehensions[i].questions[newQuestionIndex].id].response !== undefined){
          setSelectedResponse(responseDict[comprehensions[i].questions[newQuestionIndex].id].response)
        }else{
          setSelectedResponse("")
        }
        setQuestionsInComprehension(comprehensions[i].questions.length)
        setQuestions(comprehensions[i].questions)   
        break;     
      }else{
        newQuestionIndex = newQuestionIndex - tempQuestionCount
      }
    }
  }

  const handleReportQuestion = (e) => {
    setShowReportQuestion(false)
    postRequest('/api/report-question', { questionId: questions[questionIndex].id, quizId: quiz_id, reason: e.target.value } )
    handleNext()
  }

  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1} align="center">
        <Grid item xs={12}>
          <Card elevation={0}>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <Link underline="hover" color="inherit" href="/">
              Home 
            </Link>
            <Typography sx={{ color: 'text.secondary' }}>{exam_name}</Typography>
            <Typography sx={{ color: 'text.primary' }}>{quiz_title}</Typography>
          </Breadcrumbs>  
          <CardHeader title={exam_name +" : "+quiz_title}/>          
            <Stack direction="row" justifyContent="space-evenly">{ showComprehensionImages()}</Stack>
            <CardContent>
              <Typography gutterBottom variant="overline" component="div" align="left">
                {comprehensions && comprehensions[resultIndex].id > 0 && comprehensions[resultIndex].preText}
              </Typography>
              
              {comprehensions && comprehensions[resultIndex].id > 0 && comprehensions[resultIndex].audioUrl?
              <>
              <Button variant="contained" onClick={playClip}>Play Clip</Button><div/>
              <audio controls id="audio_tag">
                <source src={comprehensions && comprehensions[resultIndex].id > 0 && comprehensions[resultIndex].audioUrl} />
                Your browser does not support the audio element.
              </audio></>:<Typography gutterBottom variant="body" 
              component="div" align="left" 
              dangerouslySetInnerHTML = {{__html: comprehensions && comprehensions[resultIndex].id > 0 && DOMPurify.sanitize(comprehensions[resultIndex].comprehensionText)}}/>}
              <Typography gutterBottom variant="overline" component="div" align="left">
                {comprehensions && comprehensions[resultIndex].id > 0 && comprehensions[resultIndex].postText}
              </Typography>
            </CardContent>
            <CardContent>
              <Stack direction="row" justifyContent="space-between"> 
              <Paper variant="outlined" sx={{ padding: 1, bgcolor:"#e0e0e0", fontSize:18 }}>
                Question  {questionDisplayIndex + 1} of {totalQuestions}
              </Paper>              
              <Paper variant="outlined" sx={{ padding: 1, bgcolor:"#e0e0e0", fontSize:18 }}>
                {minutes}:{seconds}
              </Paper>
              <Typography gutterBottom variant="h6" component="div"><Link component="button"
               onClick={() => {setShowReportQuestion(true)}}>Report this Question</Link>
              </Typography>
              </Stack>
              <Typography gutterBottom variant="h4" 
              component="div" align="center" 
              dangerouslySetInnerHTML = {{__html: comprehensions && comprehensions[resultIndex].questions && DOMPurify.sanitize(comprehensions[resultIndex].questions[questionIndex].question)}}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>        
        { 
          buildResponseTile()  
        }
        </Grid>
        <Grid container spacing={1} margin={1} justifyContent="center">        
        {
          buildBottomPanel()
        }
          <Grid item xs={12}>
            <Button variant="contained" sx={{backgroundColor: purple[500]}} onClick={() => setShowFinalSubmitDialog(true)}>Submit Exam</Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showAnswerDialog}
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNext}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign="center">{correctAnswer ? 'Correct !' : 'Incorrect'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" textAlign="center">
            {questions && questions[questionIndex].questionType =="select" ? questions[questionIndex][`option` + [questions[questionIndex].answer]]:questions && (questions[questionIndex].questionType =="input" || questions[questionIndex].questionType =="sequence") ?questions[questionIndex].answer: ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={() => {setShowReportQuestion(true)}}>Report this Question</Button>
          <Button variant="outlined" onClick={()=>setShowAnswerDialog(false)}>Go Back to Question</Button>
          {questionDisplayIndex==totalQuestions-1?"":<Button ref={nextRef} variant="contained" autoFocus onClick={handleNext}>Next</Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={showReportQuestion}
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleReportQuestion}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign="center">Choose a reason to report this question</DialogTitle>
        <DialogContent>
          <Grid item xs={6}>
          <Button variant="outlined" sx={{ textTransform: "none", marginTop: "5px"}} fullWidth onClick={handleReportQuestion} value="Not readable">Question not readable.</Button>
          <Button variant="outlined" sx={{ textTransform: "none", marginTop: "5px"}} fullWidth onClick={handleReportQuestion} value="Unclear Images">Diagram or images for question are not clear or sufficient.</Button>
          <Button variant="outlined" sx={{ textTransform: "none", marginTop: "5px"}} fullWidth onClick={handleReportQuestion} value="No correct options">Answer is not available in given options.</Button>
          <Button variant="outlined" sx={{ textTransform: "none", marginTop: "5px"}} fullWidth onClick={handleReportQuestion} value="others">Others (Reason not available).</Button>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="outlined" onClick={() => {setShowReportQuestion(false)}}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
      maxWidth="xs"
      open={showFinalSubmitDialog}>
        <DialogTitle align='center'>Submit Exam</DialogTitle>
        <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description" textAlign="center">Submitting the exam would close the exam. If you want to review your responses please cancel and go back to exam.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={()=> setShowFinalSubmitDialog(false)}>
            Cancel
          </Button>
          {(comprehensions && comprehensions[resultIndex].questions[questionIndex].questionType == "essay")?
          <Button  variant="contained" onClick={() => submitExam(true)}>Submit</Button>:
          <Button  variant="contained" onClick={() => submitExam(false)}>Submit</Button>
          
          } 
        </DialogActions>
      </Dialog>
      <Dialog
      maxWidth="xs"
      open={showRefreshDialog}>
        <DialogTitle align='center'>Avoid Refreshing during exam</DialogTitle>
        <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description" textAlign="center">Refereshing the page in the middle of exam may pre-maturedly submit your exam and start a new one. Please avoid refreshing during exam.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=> setShowRefreshDialog(false)}>
            Cancel
          </Button>         
        </DialogActions>
      </Dialog>
    </Container>
  )
}
export default QuestionPage