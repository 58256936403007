import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Stack, Grid, Container, Card, CardContent, Typography, Button} from '@mui/material';

function ResultPage() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const { correctQuestions, totalQuestions, score, quizId, attemptedTestId, quizTitle} = state; // Read values passed on state
    const handleDetail = () => {
      navigate('/detail', { state: { attemptedTestId: attemptedTestId, quizTitle: quizTitle, date: (new Date()).toLocaleDateString(), totalScore: score} })
    };
  
  return (
    <Container sx={{ py: 1 }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card align="center">
            <CardContent>
            <Typography gutterBottom variant="h4" component="div">
            Congratulations on completing <b>{quizTitle}</b> !
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
              Correct Questions: {correctQuestions}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
              Total Questions: {totalQuestions}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
              Score: {score}
              </Typography>
              <Stack direction="row" sx={{justifyContent:"center"}}>

              <Button variant="contained" onClick={() => handleDetail()} style={{margin:5}}>
                View Details
              </Button>
              <Button component={Link} to="/" variant="contained" color="primary" style={{margin:5}}>
                Take Another Quiz
              </Button>              
              </Stack>
            </CardContent>
          </Card>
          </Grid>
          </Grid>
          </Container>
  )
}

export default ResultPage